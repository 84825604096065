<template>
  <v-container class="cryptomation-container">
    <h1 class="mb-1">Verify Email</h1>
    <v-sheet rounded class="pa-10">
      <div v-if="!requested && !userDataLoaded">
        <v-progress-circular
        indeterminate
        >
        </v-progress-circular><br>
        Loading user data ....
      </div>

      <div v-if="!requested && userDataLoaded">
        <v-progress-circular
        indeterminate
        >
        </v-progress-circular><br>
        Sending... verification email to {{ userData.email }}
      </div>

      <div v-if="requested">
        <div v-if="!verified">
          We have sent an email to 
          {{ userData.email }} <br><br>
          To proceed, you must verify your email address. Please check your "Spam" or "Bulk Email" folders.<br>
          If you haven't received the verification email, you can also resend an email email by clicking the resend button below.<br>
          <v-btn>
            
          </v-btn>
            <v-btn
              elevation="5"
              :disabled="countdown != 0"
              color="success"
              block
              @click.prevent="verifyEmail()"
              v-if="!verified"
              >Resend Verification Email ({{ countdown }})
            </v-btn>
        </div>
        
        <div v-if="verified">
          <v-alert color="green" type="success" v-if="verified">
            You have been verified.
          </v-alert>
        </div>
        
      </div>

    </v-sheet>
  </v-container>
</template>

<script>
import { requestVerificationEmail } from "@/services/api/user";
import { mapActions } from "vuex";

export default {
  name: "VerifyEmail",
  data() {
    return {
      countdown: 0,
      verified: false,
      requested: false,
      counterIntervalID: 0,
      checkingVerifyStatusIntervalID: 0,
      userDataLoaded: false,
    };
  },
  mounted() {
    this.checkVerificationStatus();
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    sleep(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async checkVerificationStatus(){
      while(this.userData.email == ""){
        await this.sleep(1000);
      }
      this.userDataLoaded = true

      if (this.userData.verified == false){
        this.verifyEmail();
      } else {
        this.verified = true;
        this.requested = true;
      }
    },
    async verifyEmail() {
      let result = await requestVerificationEmail();
      if (result != null) {
        if (this.counterIntervalID != 0){
          clearInterval(this.counterIntervalID);
          this.counterIntervalID = 0;
        }

        if (this.checkingVerifyStatusIntervalID != 0){
          clearInterval(this.checkingVerifyStatusIntervalID);
          this.checkingVerifyStatusIntervalID = 0;
        }

        this.requested = true;
        this.countdown = 150;
        this.counterIntervalID = window.setInterval(() => {
          this.countDown();
        }, 1000);
        this.checkingVerifyStatusIntervalID = window.setInterval(() => {
          this.checkVerifyStatus();
        }, 5000);
      }
    },
    async countDown() {
      if (this.countdown != 0) {
        this.countdown = this.countdown - 1;
      } else {
        clearInterval(this.counterIntervalID)
        this.counterIntervalID = 0;
      }
    },
    async checkVerifyStatus() {
      this.getUserInfo();
      if (this.userData.verified == true){
        this.verified = true;
      }
    }
  },
  computed: {
    userData() {
      let user_data = this.$store.getters.user || { email: "", name: "",verified: false, sign_in_provider: null };
      return user_data;
    },
  },
};
</script>
